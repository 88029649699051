<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

/**
 * Starter page component
 */
export default {
  page() {
    return {
      title: this.title,
      meta: [{ name: "description" }],
    };
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Starter page",
      items: [
        {
          text: "Utility",
          href: "/",
        },
        {
          text: "Starter page",
          active: true,
        },
      ],

      ismobile: false,
      activeNames: [],
      activeapp: [],
      showmodel: "1",
      userinfo: [],
      admininfo: {
        username: "",
        phone: "",
        country: 86,
      },
      userpassword: "",
      reinputuserpassword: "",
      loading: true,
      adminid: 0,
      showsuccess: false,
      countrylist: [],
    };
  },
  mounted() {
    this.title = this.$t("menuitems.admin.add");
    this.items = [
      {
        text: this.$t("menuitems.admin.text"),
        href: "/",
      },
      {
        text: this.$t("menuitems.admin.add"),
        active: true,
      },
    ];

    this.userinfo = JSON.parse(localStorage.getItem("user"));
    let flag = navigator.userAgent.match(
      /(phone|pod|iPhone|iPod|ios|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
    if (flag) {
      this.ismobile = true;
    }
    if (this.$route.query.id) {
      this.adminid = this.$route.query.id;
      this.getgadmindetail();
    } else {
      this.loading = false;
    }
    this.countrylist = [
      {
        value: 86,
        label: this.$t("global.country.china"),
      },
      {
        value: 1,
        label: this.$t("global.country.usa"),
      },
      {
        value: 81,
        label: this.$t("global.country.japan"),
      },
      {
        value: 44,
        label: this.$t("global.country.uk"),
      },
      {
        value: 34,
        label: this.$t("global.country.spain"),
      },
      {
        value: 33,
        label: this.$t("global.country.french"),
      },
      {
        value: 60,
        label: this.$t("global.country.malaysia"),
      },
      {
        value: 65,
        label: this.$t("global.country.singapore"),
      },
    ];
  },
  methods: {
    getgadmindetail() {
      var that = this;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "getadminuserinfo",
            id: that.adminid,
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.loading = false;
          let newdata=response.data.data
          that.admininfo={
            username: newdata.username,
            phone: newdata.phone.substring(newdata.phone.length-11),
            country: parseInt(newdata.phone.substring(1,3)),
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    addadminuser() {
      var that = this;
      if (!that.admininfo.username) {
        that.$message.error(that.$t("admin.add.usernotintut"));
        return;
      }
      if (that.admininfo.username < 4) {
        that.$message.error(that.$t("admin.add.userlong"));
        return;
      }
      if (!that.admininfo.phone) {
        that.$message.error(that.$t("admin.add.phonenotintut"));
        return;
      }
      if (that.admininfo.phone < 11) {
        that.$message.error(that.$t("admin.add.phonelong"));
        return;
      }
      if (!that.userpassword) {
        that.$message.error(that.$t("admin.add.passwordnotinput"));
        return;
      }
      if (!that.reinputuserpassword) {
        that.$message.error(that.$t("admin.add.passwordnotinputcheck"));
        return;
      }
      if (that.userpassword.length < 6) {
        that.$message.error(that.$t("admin.add.passwordlong"));
        return;
      }
      if (that.userpassword != that.reinputuserpassword) {
        that.$message.error(that.$t("admin.add.passnotmarch"));
        return;
      }
      let postdata = {
        action: "addnewadmin",
        id: that.adminid,
        phone: that.admininfo.country + that.admininfo.phone,
        username: that.admininfo.username,
        password: that.reinputuserpassword,
      };
      that.$axios
        .post(that.apiuri, postdata, {
          headers: { Openid: that.userinfo.openid },
        })
        .then(function (response) {
          that.$toast.clear();
          if (response.data.status == 200) {
            that.showsuccess = true;
          } else {
            if (response.data.msg) {
              that.$message.error(
                that.$t("admin.add.error." + response.data.msg)
              );
            } else {
              that.$message.error(that.$t("global.delete.500"));
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    checkinputphone(val) {
      console.log(val);
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body" v-loading="loading" v-if="!showsuccess">
            <form class="form-group" id="adminaddform">
              <div class="form-group">
                <label for="username">{{ $t("admin.add.username") }}</label>
                <input
                  type="text"
                  class="form-control"
                  id="username"
                  name="username"
                  v-model="admininfo.username"
                  :placeholder="$t('admin.add.username')"
                />
              </div>
              <div class="form-group">
                <label for="phone">{{ $t("admin.add.phone") }}</label>
                <el-row>
                  <el-col :span="6"
                    ><div class="grid-content bg-purple">
                      <el-select
                        v-model="admininfo.country"
                        :placeholder="$t('admin.add.selectcountry')"
                        filterable
                      >
                        <el-option
                          v-for="item in countrylist"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        >
                          <span style="float: left">{{ item.label }}</span>
                          <span
                            style="
                              float: right;
                              color: #8492a6;
                              font-size: 13px;
                            "
                            >{{ item.value }}</span
                          >
                        </el-option>
                      </el-select>
                    </div></el-col
                  >
                  <el-col :span="18"
                    ><div class="grid-content bg-purple-light">
                      <el-input
                        :placeholder="$t('admin.add.phone')"
                        v-model="admininfo.phone"
                        maxlength="11"
                      >
                      </el-input></div
                  ></el-col>
                </el-row>
              </div>
              <div class="form-group">
                <label for="password">{{ $t("admin.add.password") }}</label>
                <el-input
                  :placeholder="$t('admin.add.password')"
                  v-model="userpassword"
                  show-password
                ></el-input>
              </div>
              <div class="form-group">
                <label for="password">{{
                  $t("admin.add.reinputpassword")
                }}</label>
                <el-input
                  :placeholder="$t('admin.add.reinputpassword')"
                  v-model="reinputuserpassword"
                  show-password
                ></el-input>
              </div>
              <el-row :gutter="20">
                <el-col :span="13"
                  ><div class="grid-content bg-purple text-right">
                    <a
                      href="javascript:;"
                      class="btn btn-success btn-fw"
                      @click="addadminuser"
                      >{{ $t("goods.additem.save") }}</a
                    >
                  </div></el-col
                >
                <el-col :span="11"
                  ><div class="grid-content bg-purple text-right">
                    <router-link
                      :to="{
                        path: '/admin/list',
                      }"
                    >
                      <a href="javascript:;" class="btn btn-light btn-fw">{{
                        $t("global.return")
                      }}</a>
                    </router-link>
                  </div></el-col
                >
              </el-row>
            </form>
          </div>
          <div v-else>
            <el-result icon="success" :title="$t('global.success')">
              <template slot="extra">
                <router-link
                  :to="{
                    path: '/admin/list',
                  }"
                >
                  <el-button type="primary" size="medium">{{
                    $t("global.return")
                  }}</el-button>
                </router-link>
              </template>
            </el-result>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>